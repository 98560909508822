@import 'ds/styles/includes/index.scss';

.editor {
    width: 100%;
    padding: 0px 12px;
    border: 1px solid var(--neutral-300);
    border-radius: $primary-radius;
    cursor: text;
    @include focus-outline();
    &:hover {
        border: 1px solid var(--primary-500);
    }
}

.toolbar {
    border-radius: $primary-radius;
    background-color: var(--surface-forefront);
    border: 1px solid var(--neutral-300);
}

.toolbar > * > * {
    background-color: var(--surface-forefront);
    border: 1px solid var(--neutral-300);
    color: var(--neutral-700);
    &:hover {
        background-color: var(--neutral-100);
        box-shadow: none;
    }
}

.toolbar :global(.rdw-dropdown-optionwrapper) {
    background-color: var(--surface-forefront);
    color: var(--neutral-700);
    border: 1px solid var(--neutral-300);
    box-shadow: none;
}

.toolbar :global(.rdw-dropdown-wrapper) {
    &:hover {
        border: 1px solid var(--neutral-300);
        background-color: var(--neutral-100);
        box-shadow: none;
    }
}

.toolbar :global(a) {
    color: var(--neutral-700) !important;
}
