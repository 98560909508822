.cardBrandIcon {
    display: inline-flex;
    align-items: center;
    aspect-ratio: 40/25;

    svg {
        width: 100%;
        height: 100%;
    }

    &.sm {
        width: 32px;
    }

    &.md {
        width: 48px;
    }

    &.lg {
        width: 56px;
    }
}
