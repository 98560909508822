@import 'ds/styles/includes/index.scss';

.content {
    max-width: 500px;
    border-radius: $primary-radius;
    border: none;
    border: 1px solid var(--neutral-200);
    white-space: normal;
    z-index: 1;
    background-color: var(--neutral-000);
    color: var(--neutral-800);
}
